import Header from "econify/components/Header"
import React from "react"

import * as styles from "../routes/GetStarted/GetStarted.module.css"
import GetStared from "econify/routes/GetStarted"
import Footer from "econify/components/Footer"

const GetStartedPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <GetStared />
      </div>
      <Footer />
    </>
  )
}

export default GetStartedPage
