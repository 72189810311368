import React, { FormEventHandler, useCallback, useMemo, useState } from "react"
import Recaptcha from "react-recaptcha"
import { InView } from "react-intersection-observer"

import * as styles from "./RecaptchaForm.module.css"

type RecaptchaFormProps = {
  submitDisabled: boolean
  buildFormData: () => { [key: string]: unknown }
  resetForm: () => void
  submissionUrl: string
}

const recaptchaScriptId = "recaptcha-script"
const recaptchaScriptUrl =
  "https://www.google.com/recaptcha/api.js?render=explicit"

const RecaptchaForm: React.FC<RecaptchaFormProps> = ({
  submitDisabled,
  buildFormData,
  resetForm,
  children,
  submissionUrl,
}) => {
  const [recaptchaToken, setRecaptchaToken] = useState("")
  const [submittingForm, setSubmittingForm] = useState(false)
  const [submitMessage, setSubmitMessage] = useState("")
  const [submitError, setSubmitError] = useState(false)

  const verifyRecaptchaCallback = useCallback(
    (recaptchaToken: string) => {
      setRecaptchaToken(recaptchaToken)
    },
    [setRecaptchaToken]
  )
  const expiredRecaptchaCallback = useCallback(() => {
    setRecaptchaToken("")
  }, [setRecaptchaToken])
  const loadRecaptchaScript = useCallback(() => {
    if (window !== undefined) {
      if (!document.getElementById(recaptchaScriptId)) {
        const script = document.createElement("script")
        script.id = recaptchaScriptId
        script.src = recaptchaScriptUrl
        window.document.body.appendChild(script)
      }
    }
  }, [])

  const submitForm: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    if (!recaptchaToken) return
    setSubmittingForm(true)

    const formData = {
      ...buildFormData(),
      recaptchaToken,
    }

    fetch(submissionUrl, {
      method: "POST",
      body: JSON.stringify(formData),
    }).then((response: any) => {
      if (response.status !== 200) {
        setSubmittingForm(false)
        setSubmitMessage("Error submitting request. Please try again later.")
        setSubmitError(true)
        return
      }
      setSubmittingForm(false)
      setSubmitError(false)
      setSubmitMessage(
        "Request successfully sent! We'll get back to you as soon as possible"
      )
      resetForm()
    })
  }

  const disableSubmit = useMemo(() => {
    return submittingForm || submitDisabled || !recaptchaToken
  }, [submittingForm, submitDisabled, recaptchaToken])

  return (
    <InView
      as="div"
      onChange={(inView, _) => {
        if (inView) loadRecaptchaScript()
      }}
    >
      <form
        className={styles.recaptchaForm}
        method="POST"
        onSubmit={submitForm}
      >
        {children}
        <section className={styles.section}>
          {submitMessage ? (
            <p className={`${submitError ? styles.error : styles.success}`} id={`form-submitted-${submitError ? 'error' : 'success'}-message`}>
              {submitMessage}
            </p>
          ) : null}
          <div className={styles.btnRow}>
            <button
              type="submit"
              className={`${styles.formButton} ${styles.submitBtn}`}
              disabled={disableSubmit}
            >
              SEND
            </button>
            <button
              type="reset"
              className={`${styles.formButton} ${styles.clearBtn}`}
              onClick={resetForm}
              disabled={submittingForm}
            >
              CLEAR
            </button>
            {process.env.SITE_KEY ? (
              <Recaptcha
                sitekey={process.env.SITE_KEY}
                render="explicit"
                onloadCallback={() => null}
                verifyCallback={verifyRecaptchaCallback}
                expiredCallback={expiredRecaptchaCallback}
              />
            ) : null}
          </div>
        </section>
      </form>
    </InView>
  )
}

export default RecaptchaForm
