import React from "react"

import services from "econify/data/services/services.json"

import * as styles from "./GetStarted.module.css"
import GetStartedForm from "./GetStartedForm"
import { Service } from "./GetStartedForm/ServiceRow/ServiceRow"

const GetStared: React.FC = () => {
  return (
    <>
      <div className={styles.section}>
        <h2>Work with us</h2>
        <hr />
        <GetStartedForm services={services as Service[]} />
      </div>
    </>
  )
}

export default GetStared
