import React, { useMemo, useState } from "react"

import * as styles from "./GetStartedForm.module.css"
import RecaptchaForm from "econify/components/RecaptchaForm"
import ServiceRow, { Service } from "./ServiceRow/ServiceRow"
import { CONTACT_SUBMISSION_URL } from "econify/contstants"

interface ServiceGridProps {
  services: Service[]
}

type ServiceFormData = {
  email: string
  message: string
  selectedServices: {
    service: string
    staff?: string
  }[]
}
const baseServiceFormData = {
  email: "",
  message: "",
  selectedServices: [],
}

const GetStartedForm: React.FC<ServiceGridProps> = ({ services }) => {
  const [serviceFormData, setServiceFormData] =
    useState<ServiceFormData>(baseServiceFormData)
  const [collapseAllServices, setCollapseAllServices] = useState(false)

  const onResetForm = () => {
    setServiceFormData(baseServiceFormData)
    setCollapseAllServices(true)
    setTimeout(() => setCollapseAllServices(false), 100)
  }

  const onServiceSelected = (serviceId: string) => {
    setServiceFormData(prevServiceFormData => {
      let services = [...prevServiceFormData.selectedServices]
      const foundServiceIndex = services.findIndex(s => s.service === serviceId)
      if (foundServiceIndex >= 0) {
        services.splice(foundServiceIndex, 1)
      } else {
        services.push({ service: serviceId, staff: "1" })
      }
      return {
        ...prevServiceFormData,
        selectedServices: services,
      }
    })
  }
  const onServiceStaffChanged = (serviceId: string, increment: boolean) => {
    setServiceFormData(prevServiceFormData => {
      let services = [...prevServiceFormData.selectedServices]
      const currentServiceIndex = services.findIndex(s => s.service === serviceId)
      const currentService = services[currentServiceIndex]

      if (!currentService && increment) {
        services = services.concat({ service: serviceId, staff: "1" })
      } else if (!increment && currentService?.staff === "1") {
        services = services.filter(s => s.service !== serviceId)
      } else {
        let newStaff = currentService?.staff
        if (increment) {
          if (currentService?.staff === "9") {
            newStaff = "10+"
          } else {
            newStaff = (parseInt(currentService?.staff || "2") + 1).toString()
          }
        } else {
          newStaff = (parseInt(currentService?.staff || "2") - 1).toString()
        }
        services[currentServiceIndex].staff = newStaff
      }
      return {
        ...prevServiceFormData,
        selectedServices: services,
      }
    })
  }

  const buildFormData = () => {
    return {
      ...serviceFormData,
      selectedServices: serviceFormData.selectedServices.map(selectedService => {
        const serviceObject = services.find(
          s => s.id === selectedService.service
        )
        if (serviceObject?.type === "audit") {
          delete selectedService.staff
        }
        return {
          ...selectedService,
          type: serviceObject?.type
        }
      }),
    }
  }

  const audits = useMemo(
    () => services.filter(s => s.type === "audit"),
    [services]
  )
  const augmentations = useMemo(
    () => services.filter(s => s.type === "augmentation"),
    [services]
  )
  const servicesConfig = useMemo(
    () => [
      {
        title: "Audit",
        data: audits,
      },
      { title: "Augment", data: augmentations },
    ],
    [audits, augmentations]
  )
  const disableSubmit = useMemo(() => {
    return (
      !serviceFormData.email ||
      !serviceFormData.message ||
      !serviceFormData.selectedServices.length
    )
  }, [serviceFormData])

  return (
    <RecaptchaForm
      submitDisabled={disableSubmit}
      buildFormData={buildFormData}
      resetForm={onResetForm}
      submissionUrl={CONTACT_SUBMISSION_URL}
    >
      <section className={styles.section}>
        <div className={styles.grid}>
          {servicesConfig.map(s => (
            <div key={s.title}>
              <h3>{s.title}</h3>
              {s.data.map(service => (
                <ServiceRow
                  shouldCollapse={collapseAllServices}
                  key={service.id}
                  onStaffChange={(increment: boolean) =>
                    onServiceStaffChanged(service.id, increment)
                  }
                  numberStaff={
                    serviceFormData.selectedServices.find(
                      s => s.service === service.id
                    )?.staff ?? ""
                  }
                  service={service}
                  onServiceSelected={onServiceSelected}
                  isSelected={
                    !!serviceFormData.selectedServices.find(
                      s => s.service === service.id
                    )
                  }
                />
              ))}
            </div>
          ))}
        </div>
      </section>
      <section className={styles.section}>
        <div>
          <input
            aria-label="email address"
            type="email"
            required
            className={styles.formControl}
            value={serviceFormData.email}
            placeholder={"Your email"}
            onChange={e =>
              setServiceFormData({
                ...serviceFormData,
                email: e.target.value,
              })
            }
          />
        </div>
        <div>
          <textarea
            aria-label="message to us"
            rows={5}
            className={styles.formControl}
            value={serviceFormData.message}
            placeholder="How can we help?"
            onChange={e =>
              setServiceFormData({
                ...serviceFormData,
                message: e.target.value,
              })
            }
          />
        </div>
      </section>
    </RecaptchaForm>
  )
}

export default GetStartedForm
