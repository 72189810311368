import React from "react"
import * as styles from "./TouchInput.module.css"

type TouchInputProps = {
  title: string
  value: string
  onIncrement: () => void
  onDecrement: () => void
  max?: string
}

const TouchInput: React.FC<TouchInputProps> = ({
  value,
  onDecrement,
  onIncrement,
  title,
  max,
}) => {
  return (
    <div>
      <span>{title}</span>
      <div className={styles.touchInputContainer}>
        <button
          aria-label="Decrement number of staff"
          type="button"
          onClick={onDecrement}
          disabled={!value}
        >
          -
        </button>
        <div>{value}</div>
        <button
          aria-label="Increment number of staff"
          type="button"
          onClick={onIncrement}
          disabled={value === max}
        >
          <span>+</span>
        </button>
      </div>
    </div>
  )
}

export default TouchInput
