import React, { SyntheticEvent } from "react"
import * as styles from "./Checkbox.module.css"

type CheckboxProps = {
  id: string
  onChange: (event: SyntheticEvent) => void
  checked: boolean
  label?: string
  small?: boolean
}

const Checkbox = ({ id, onChange, checked, label, small }: CheckboxProps) => {
  return (
    <div
      key={id}
      className={`${styles.checkbox} ${small ? styles.checkboxSmall : ""}`}
    >
      <input
        type="checkbox"
        id={id}
        value={id}
        onChange={onChange}
        checked={checked}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

export default Checkbox
