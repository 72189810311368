import React, { useEffect, useState } from "react"
import { withPrefix } from "gatsby"

import Micromark from "econify/components/Micromark"
import Checkbox from "econify/components/Checkbox"

import * as styles from "./ServiceRow.module.css"
import TouchInput from "econify/components/TouchInput"

interface Content {
  description: string
}

export interface Service {
  id: string
  service: string
  type: "audit" | "augmentation"
  content: Content[]
}

interface ServiceComponentBaseProps {
  service: Service
  numberStaff: string
  onStaffChange: (increment: boolean) => void
}

interface ServiceRowProps extends ServiceComponentBaseProps {
  onServiceSelected: (serviceId: string) => void
  isSelected: boolean
  shouldCollapse: boolean
}

interface ServiceContentProps extends ServiceComponentBaseProps {
  isExpanded?: boolean
}

const ServiceContent: React.FC<ServiceContentProps> = ({
  service,
  isExpanded,
  onStaffChange,
  numberStaff,
}) => {
  if (!service.content.length) return null

  const contentClass = `${styles.serviceContent} ${
    isExpanded ? styles.expanded : styles.collapsed
  }`

  return (
    <div className={styles.serviceContentContainer}>
      <div className={contentClass}>
        {service.content.map((content, index) => (
          <div
            key={`${service.id}-content-${index + 1}`}
            className={styles.serviceItem}
          >
            <Micromark
              className={styles.serviceDescription}
              markdown={content.description}
            />
          </div>
        ))}
        {service.type === "augmentation" ? (
          <div className={`${styles.staffSelector}`}>
            <TouchInput
              onIncrement={() => onStaffChange(true)}
              onDecrement={() => onStaffChange(false)}
              title="# Staff Required"
              max="10+"
              value={numberStaff}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const ServiceRow: React.FC<ServiceRowProps> = ({
  service,
  onServiceSelected,
  isSelected,
  onStaffChange,
  numberStaff,
  shouldCollapse,
}) => {
  const [expandedServiceId, setExpandedServiceId] = useState<string | null>(
    null
  )
  const isExpanded = expandedServiceId === service.id

  useEffect(() => {
    if (shouldCollapse) {
      setExpandedServiceId(null)
    }
  }, [shouldCollapse])

  const handleServiceClick = (serviceId: string) => {
    setExpandedServiceId(expandedServiceId === serviceId ? null : serviceId)
  }

  const onCheckboxClicked = (serviceId: string) => {
    if (!isExpanded && !isSelected) {
      setExpandedServiceId(serviceId)
    } else if (isExpanded && isSelected) {
      setExpandedServiceId(null)
    }
    onServiceSelected(serviceId)
  }

  return (
    <div className={`${styles.service}`}>
      <div className={`${styles.checkboxContainer}`}>
        <Checkbox
          checked={isSelected}
          onChange={() => onCheckboxClicked(service.id)}
          id={service.id}
        />
      </div>
      <div>
        <div
          className={styles.serviceHeader}
          role="button"
          onClick={() => handleServiceClick(service.id)}
        >
          <img
            alt="arrow icon"
            src={withPrefix("/images/arrow-right.svg")}
            className={`${styles.arrow} ${isExpanded ? styles.rotate : ""}`}
            loading="lazy"
          />
          <Micromark
            className={styles.serviceTitle}
            markdown={service.service}
          />
        </div>
        <ServiceContent
          isExpanded={isExpanded}
          service={service}
          onStaffChange={onStaffChange}
          numberStaff={numberStaff}
        />
      </div>
    </div>
  )
}

export default ServiceRow
