// extracted by mini-css-extract-plugin
export var btnRow = "RecaptchaForm-module--btnRow--65062";
export var clearBtn = "RecaptchaForm-module--clearBtn--55163";
export var error = "RecaptchaForm-module--error--b3109";
export var formButton = "RecaptchaForm-module--formButton--6bfaa";
export var formControl = "RecaptchaForm-module--formControl--34683";
export var grid = "RecaptchaForm-module--grid--375e2";
export var recaptchaForm = "RecaptchaForm-module--recaptchaForm--36598";
export var section = "RecaptchaForm-module--section--26ec8";
export var submitBtn = "RecaptchaForm-module--submitBtn--f05ef";
export var success = "RecaptchaForm-module--success--d57d8";